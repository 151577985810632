<template>
  <div class="page product-page">
    <Header />
    <HotlineMobileCard/>
    <div class="container">
        <div class="h5 font-weight--sm--400 text--black text--sm--center mb--sm--20 mt--sm--15 forTabletUp">Danh sách sản phẩm</div>
        <div class="row mt--sm--4 mt--md--0">
          <div class="col col--sm--12 col--md--6 mb--sm--3 mb--md--6 flagSearch" v-for="(product, index) in productsSearch" :key="index">
            <ProductCard
              :product="product"
            />
          </div>
           <infinite-loading @infinite="infiniteHandler" v-if="(paginationSearch.total / paginationSearch.pageSize) > 1"><div slot="no-more"></div><div slot="no-results"></div></infinite-loading>
          <div class="col col--sm--12 d--sm--flex justify-content--sm--center align-items--sm--center flex-direction--sm--column mb--sm--9 mb--md--20 mt--sm--9 mt--md--20" v-if="$_.isEmpty(productsSearch)">
            <div class="text--sm--center mb--sm--12 mb--md--20">Không có sản phẩm nào để hiển thị!</div>
          </div>
        </div>
    </div>
    <Footer/>
    <FooterMobile/>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import FooterMobile from '@/components/FooterMobile.vue'
import ProductCard from '@/components/ProductCard.vue'
import HotlineMobileCard from '@/components/HotlineMobileCard.vue'
import InfiniteLoading from 'vue-infinite-loading'

export default {
  components: {
    Header,
    Footer,
    FooterMobile,
    ProductCard,
    HotlineMobileCard,
    InfiniteLoading
  },
  methods: {
    infiniteHandler: function ($state) {
      this.$store.commit('product/setSearchKey', this.$route.query.searchKey || '')
      // getTotalResults beffore to update pagination.
      this.$store.dispatch('product/getTotalProductsSearch').then(() => {
        if (this.paginationSearch.currentPage <= (this.paginationSearch.total / this.paginationSearch.pageSize)) {
          this.$store.dispatch('product/getProductsSearch').then(() => {
            $state.loaded()
            // Set curent page after load
            this.paginationSearch.currentPage += 1

            // Check if is last of pages.
            if (this.paginationSearch.currentPage >= (this.paginationSearch.total / this.paginationSearch.pageSize)) {
              $state.complete()
            }
          })
        } else {
          $state.complete()
        }
      })
    }
  },
  computed: {
    paginationSearch: {
      get () {
        return this.$store.state.product.paginationSearch
      },
      set (paginationSearch) {
        this.$store.commit('product/setpaginationSearch', paginationSearch)
      }

    },
    productsSearch () {
      return this.$store.state.product.productsSearch
    }
  },
  data () {
    return {}
  },
  mounted () {
    this.$store.dispatch('setHeaderMobile', {
      heading: false,
      headingText: '',
      back: true,
      search: true,
      notify: true,
      cart: true
    })
    this.$store.dispatch('setFooterMobile', false)

    // Seach product on mounted
    this.$store.commit('product/setSearchKey', this.$route.query.searchKey || '')
    this.$store.dispatch('product/getTotalProductsSearch')
  }
}
</script>

<style lang="scss">
  .flagSearch:nth-child(2n+2) {
    .product-card__info {
      background-color: #eff9f8;
    }
  }
</style>
